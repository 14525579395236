'use strict';

class MessageItemController {
  private message;
  private presences;
  private onViewAttachment;
  private preview;

  constructor(
    private $q,
    private $http,
    private $timeout,
    private SCConfiguration,
  ) {
    this.preview = {};
  }

  $onInit() {
    const playerLinks = _.get(this.message, '$meta.playerLinks', []);

    const playerIds = playerLinks.map((link) => link.match(/platform\/player\/([a-f\d]{24})/)[1]);

    this.$timeout(() => {
      this.$q
        .all(playerIds.map((id) => this.fetchPlayer(id)))
        .then((players) => (this.preview.players = players));
    }, 200);
  }

  fetchPlayer(id) {
    return this.$http
      .get(this.SCConfiguration.getEndpoint() + '/api/players/' + id)
      .then((res) => res.data);
  }

  isToday(ts) {
    return new Date(ts * 1000).toDateString() === new Date().toDateString();
  }

  isUserOnline(userId) {
    return !!this.presences[userId];
  }

  isSystemMessage(message) {
    return message.type === 0;
  }
}

angular.module('app.communication').component('messageItem', {
  templateUrl: 'communication/components/message-item.html',
  controller: MessageItemController,
  bindings: {
    message: '<',
    grouped: '<',
    presences: '<',
    onViewAttachment: '&',
  },
});
