'use strict';

class NotificationsHintController {
  private $notificationCenter;

  constructor(NotificationCenter) {
    this.$notificationCenter = NotificationCenter;
  }

  get isSupported() {
    return this.$notificationCenter.native.isSupported;
  }

  get isEnabled() {
    return this.$notificationCenter.native.isEnabled;
  }

  get isDenied() {
    return this.$notificationCenter.native.isDenied;
  }

  requestNotificationPermissions() {
    this.$notificationCenter.native.requestPermissions();
  }
}

angular.module('app.communication').component('notificationsHint', {
  templateUrl: 'communication/components/notifications-hint.html',
  controller: NotificationsHintController,
});
