'use strict';

class InboxController {
  private isOnline;
  private isSocketError;
  private inboxKey;
  private inboxes;

  private user;
  private inboxKeys;
  private paginator;
  private state;
  private channels: [any];
  private oldestTimestamp;

  private $chatContainer;

  constructor(
    private $timeout,
    private $http,
    private $scope,
    private $state,
    private $filter,
    private UserService,
    private CommunicationAPI,
    private CommunicationService,
    private Paginator,
    private WakeUpTimer,
  ) {
    this.user = this.UserService.getUser();
    this.state = this.CommunicationService.getState();
  }

  $onInit() {
    this.inboxKeys = _.keys(this.state.inboxes.toJS());
    this.oldestTimestamp = '';
    this.CommunicationService.refreshInboxStats();
    this.paginator = this.Paginator.getInstance({
      strategy: 'PaginatorNormalStrategy',
      limit: 20,
      pagingFunction: ({skip, limit}, appendResult) => {
        const shouldAppendResult = this.oldestTimestamp ? true : false;
        return this.CommunicationAPI.fetchChannels(
          this.inboxKey,
          {before_ts: this.oldestTimestamp, limit},
          shouldAppendResult,
        )
          .then((channels) => {
            const oldestCh: any = _.first(_.orderBy(channels, 'last_message_ts'));
            this.oldestTimestamp = oldestCh ? oldestCh.last_message_ts : '';
            return channels;
          })
          .catch(angular.noop);
      },
    });
  }

  $onChanges(changesObj) {
    if (changesObj && changesObj.inboxes && changesObj.inboxes.currentValue) {
      const immutableChannels = changesObj.inboxes.currentValue.get(this.inboxKey);
      if (immutableChannels) {
        this.channels = immutableChannels
          .sortBy((ch) => ch.get('last_message_ts'))
          .reverse()
          .toJS();
      }
    }

    if (
      changesObj &&
      changesObj.isSocketReady &&
      !changesObj.isSocketReady.isFirstChange() &&
      changesObj.isSocketReady.currentValue &&
      changesObj.isSocketReady.currentValue !== changesObj.isSocketReady.previousValue
    ) {
      this.$onInit();
    }
  }

  leaveChannel(channel, inboxKey) {
    const channelIndex = _.findIndex(this.channels, (ch) => ch.id + '' === channel.id + '');
    const nextIndex = channelIndex > 0 ? channelIndex - 1 : channelIndex + 1;
    const nextChannel = this.channels[nextIndex];

    this.CommunicationAPI.leaveChannel(channel, inboxKey).then(() => {
      if (nextChannel) {
        this.$state.go('root.communication.inbox.channel', {channelId: nextChannel.id});
      } else {
        this.$state.go('root.communication.inbox');
      }
    });
  }
}

angular.module('app.communication').component('inbox', {
  templateUrl: 'communication/components/inbox.html',
  controller: InboxController,
  controllerAs: '$inboxCtrl',
  bindings: {
    inboxKey: '<',
    inboxes: '<',
    isOnline: '<',
    isSocketReady: '<',
  },
});
