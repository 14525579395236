'use strict';
var MessageSenderAvatarController = (function () {
    function MessageSenderAvatarController($q, CommunicationMemberCache, _) {
        this.$q = $q;
        this.CommunicationMemberCache = CommunicationMemberCache;
        this._ = _;
    }
    MessageSenderAvatarController.prototype.$onInit = function () {
        var _this = this;
        this.$promise = this.$q
            .all({
            organization: this.organizationId
                ? this.CommunicationMemberCache.getMember('org', this.organizationId)
                : this.$q.resolve(),
            user: this.CommunicationMemberCache.getMember('user', this.userId),
        })
            .then(function (_a) {
            var user = _a.user, organization = _a.organization;
            _this.user = user;
            _this.organization = organization;
        });
    };
    return MessageSenderAvatarController;
}());
angular.module('app.communication').component('messageSenderAvatar', {
    templateUrl: 'communication/components/message-sender-avatar.html',
    controller: MessageSenderAvatarController,
    bindings: {
        organizationId: '<',
        userId: '<',
    },
});