'use strict';

class ReadReceiptsController {
  private lastMessage;
  private readReceipts;
  private channel;
  private user;
  private inboxKey;

  private organizationId; // should be extracted from inboxKey onInit
  private byId;
  private seenBy;
  private seenByStr;
  private isVisible;

  private $communicationAPI;

  constructor(
    private $filter,
    CommunicationAPI,
  ) {
    this.$communicationAPI = CommunicationAPI;
  }

  $onInit() {}

  $onChanges(changesObj) {
    if (changesObj.channel && changesObj.channel.currentValue) {
      const channel = changesObj.channel.currentValue;
      this.byId = {};
      channel.users.forEach((user) => (this.byId[user.id] = user));
      channel.organizations.forEach((org) => (this.byId[org.id] = org));
    }

    if (changesObj.inboxKey && changesObj.inboxKey.currentValue) {
      this.organizationId = changesObj.inboxKey.currentValue.split('org:')[1];
    }

    if (changesObj.readReceipts && changesObj.readReceipts.currentValue) {
      const readReceipts = changesObj.readReceipts.currentValue;

      const seenBy = readReceipts.filter((receipt) => {
        if (this.organizationId) {
          return receipt.organization_id
            ? receipt.organization_id !== this.lastMessage.organization_id &&
                receipt.organization_id !== this.organizationId
            : receipt.user_id !== this.user._id &&
                (receipt.user_id !== this.lastMessage.user_id ||
                  !!this.lastMessage.organization_id);
        }

        return receipt.organization_id
          ? receipt.organization_id !== this.lastMessage.organization_id
          : receipt.user_id !== this.user._id &&
              (receipt.user_id !== this.lastMessage.user_id || !!this.lastMessage.organization_id);
      });

      this.seenBy = _.uniqWith(
        seenBy,
        (r1, r2) =>
          r1.organization_id && r2.organization_id && r1.organization_id === r2.organization_id,
      );
      this.seenByStr = this.seenBy
        .map((receipt) => {
          if (receipt.organization_id) {
            return this.byId[receipt.organization_id].name;
          } else {
            return this.getUserDisplay(this.byId[receipt.user_id]);
          }
        })
        .join(', ');

      this.isVisible = this.seenBy.length > 0;
    }
  }

  seenByEveryone() {
    const numberOfParticipants = this.channel.users.length + this.channel.organizations.length;

    if (numberOfParticipants < 3) {
      return false;
    }

    const isLastMessageMyMessage = this.organizationId
      ? this.lastMessage.organization_id === this.organizationId
      : !this.lastMessage.organization_id && this.lastMessage.user_id === this.user._id;

    if (isLastMessageMyMessage) {
      return this.seenBy.length === numberOfParticipants - 1;
    }

    return this.seenBy.length === numberOfParticipants - 2;
  }

  getUserDisplay(user) {
    if (user) {
      return this.$filter('userDisplayShort')(user);
    }
    return '';
  }
}

angular.module('app.communication').component('readReceipts', {
  templateUrl: 'communication/components/read-receipts.html',
  controller: ReadReceiptsController,
  bindings: {
    user: '<',
    inboxKey: '<',
    lastMessage: '<',
    channel: '<',
    readReceipts: '<',
  },
});
