class NewBugReportController {
  private modalInstance;

  private user;
  private message;
  private subject;
  private url;

  private errorMessage;
  private isBusy;

  constructor(
    private $element,
    private $window,
    private CommunicationAPI,
    private UserService,
  ) {
    this.message = '';
    this.subject = '';
  }

  $onInit() {
    this.user = this.UserService.getUser();
    this.url = this.$window.location.href;
  }

  $postLink() {
    setTimeout(() => {
      this.$element.find('input').first().focus();
    }, 200);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  send() {
    const organizations = [
      {name: 'SportContract', type: 'organization', id: '58ef6e011270d16a5e259e4a'},
    ];

    this.isBusy = true;

    const inboxKey = `user:${this.user._id}`;

    this.CommunicationAPI.createChannel(
      inboxKey,
      [],
      organizations,
      this.subject,
      this.message + `\n\n [ URL causing problem: ${this.url} ]`,
    )
      .then((newChannel) => {
        this.isBusy = false;
        this.errorMessage = '';
        this.modalInstance.close({inboxKey, channel: newChannel});
      })
      .catch((err) => {
        this.isBusy = false;
        this.errorMessage = (err && err.data) || 'Failed to create converstation!';
      });
  }
}

angular.module('app.communication').component('newBugReport', {
  templateUrl: 'communication/components/modals/new-bug-report.html',
  controller: NewBugReportController,
  bindings: {
    modalInstance: '<',
  },
});
