'use strict';
var MessageListController = (function () {
    function MessageListController($element, $scope, $timeout, $filter, $uibModal, CommunicationAPI, CommunicationService) {
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.CommunicationAPI = CommunicationAPI;
        this.CommunicationService = CommunicationService;
        this.byId = {};
        this._markReadDebounced = _.debounce(this._markRead, 1000);
        this._scrollToLastMessageDebounced = _.debounce(this._scrollToLastMessage, 100);
    }
    MessageListController.prototype.$onChanges = function (changesObj) {
        var _this = this;
        if (changesObj.channel && changesObj.channel.currentValue) {
            var channel = changesObj.channel.currentValue;
            channel.users.forEach(function (user) { return (_this.byId[user.id] = user); });
            channel.organizations.forEach(function (org) { return (_this.byId[org.id] = org); });
            var _a = this.inboxKey.split(':'), inboxType = _a[0], inboxId_1 = _a[1];
            var organizations = this.channel.organizations || [];
            var users = this.channel.users || [];
            var otherUserIds = inboxType === 'user'
                ? users.filter(function (user) { return user.id !== inboxId_1; }).map(function (user) { return user.id; })
                : users.map(function (user) { return user.id; });
            var otherOrganizationIds = inboxType === 'org'
                ? organizations.filter(function (org) { return org.id !== inboxId_1; }).map(function (org) { return org.id; })
                : organizations.map(function (org) { return org.id; });
            var otherUsers = otherUserIds.map(function (id) { return ({ id: id, type: 'user' }); });
            var otherOrganizations = otherOrganizationIds.map(function (id) { return ({ id: id, type: 'org' }); });
            this.otherParticipants = otherUsers.concat(otherOrganizations);
        }
        if (changesObj.messages && changesObj.messages.currentValue) {
            var previousMessages = changesObj.messages.previousValue;
            var currentMessages = changesObj.messages.currentValue;
            var anchorMessage = void 0;
            if (previousMessages &&
                currentMessages &&
                previousMessages.size &&
                currentMessages.size &&
                previousMessages.first() !== currentMessages.first()) {
                anchorMessage = previousMessages.first();
            }
            if (anchorMessage) {
                this._scrollToMessage(anchorMessage.id, 100);
            }
            else {
                this._scrollToLastMessageDebounced();
            }
            var lastMessage = currentMessages.last();
            if (!this.isOwnMessage(lastMessage) && this.unreadCount && this.isWindowVisible) {
                this._markReadDebounced(lastMessage);
            }
        }
        if (changesObj.readReceipts &&
            changesObj.readReceipts.currentValue &&
            changesObj.readReceipts.currentValue.size) {
            this.readReceiptsByMessage = _.groupBy(changesObj.readReceipts.currentValue.toJS(), 'message_id');
        }
        if (changesObj.unreadCount && changesObj.unreadCount.currentValue && this.messages) {
            var lastMessage = this.messages.last();
            if (lastMessage && !this.isOwnMessage(lastMessage) && this.isWindowVisible) {
                this._markReadDebounced(lastMessage);
            }
        }
        if (changesObj.isWindowVisible &&
            changesObj.isWindowVisible.currentValue &&
            !changesObj.isWindowVisible.previoousValue) {
            var lastMessage = this.messages && this.messages.last();
            if (lastMessage && !this.isOwnMessage(lastMessage) && this.unreadCount) {
                this._markReadDebounced(lastMessage);
            }
        }
    };
    MessageListController.prototype.$postLink = function () {
        var _this = this;
        this.$timeout(function () {
            _this.CommunicationService.$flowUploaderInstance.assignDrop(_this.$element);
        }, 200);
    };
    MessageListController.prototype.isOwnMessage = function (message) {
        var organizationId = this.inboxKey.split('org:')[1];
        if (organizationId) {
            return message.organization_id === organizationId;
        }
        else {
            return message.user_id === this.user._id && !message.organization_id;
        }
    };
    MessageListController.prototype.isSameSenderWithPreviousMessage = function (message, previousMessage) {
        return previousMessage && previousMessage.user_id === message.user_id;
    };
    MessageListController.prototype.getOlderMessages = function () {
        if (this.messages && this.messages.size && this.hasMore) {
            this.olderMessagesPromise = this.CommunicationAPI.getOlderMessages(this.channel.id);
        }
    };
    MessageListController.prototype.leaveChannel = function () {
        var _this = this;
        var modalInstance = this.$uibModal.open({
            size: 'md',
            template: '<channel-leave-confirm-modal modal-instance="$ctrl.modalInstance" />',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                },
            ],
            controllerAs: '$ctrl',
        });
        modalInstance.result
            .then(function () {
            _this.onChannelLeave();
        })
            .catch(function () { });
    };
    MessageListController.prototype.addMembers = function () {
        var _this = this;
        var channel = this.channel;
        var modalInstance = this.$uibModal.open({
            size: 'md',
            template: '<add-participants-modal channel="$ctrl.channel" modal-instance="$ctrl.modalInstance" />',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.channel = channel;
                },
            ],
            controllerAs: '$ctrl',
        });
        modalInstance.result
            .then(function (_a) {
            var selections = _a.selections, canSeeChannelHistory = _a.canSeeChannelHistory;
            _this.CommunicationAPI.addMembers(channel, selections, canSeeChannelHistory);
        })
            .catch(function () { });
    };
    MessageListController.prototype.viewAttachment = function (message) {
        this.attachmentViewerVisible = true;
        this.activeAttachment = message.attachments[0];
    };
    MessageListController.prototype._scrollToMessage = function (id, offset) {
        var _this = this;
        if (offset === void 0) { offset = 0; }
        this.$timeout(function () {
            _this.$messagesContainer = _this.$messagesContainer || _this.$element.find('.chat-messages');
            var $msg = _this.$element.find("#msg-".concat(id));
            if ($msg.length) {
                _this.$messagesContainer.scrollTo($msg, offset, 200);
            }
        }, 0);
    };
    MessageListController.prototype._scrollToLastMessage = function () {
        this.$messagesContainer = this.$messagesContainer || this.$element.find('.chat-messages');
        var $lastItem = this.$messagesContainer.find("li.item:last");
        if ($lastItem.length) {
            this.$messagesContainer.scrollTo($lastItem, 0, 0);
        }
    };
    MessageListController.prototype._markRead = function (message) {
        this.CommunicationAPI.markRead(this.channel.id, this.inboxKey, message);
        this.$scope.$apply();
    };
    MessageListController.prototype.isUserOnline = function (userId) {
        return !!this.presences[userId];
    };
    return MessageListController;
}());
angular.module('app.communication').component('messageList', {
    templateUrl: 'communication/components/message-list.html',
    controller: MessageListController,
    bindings: {
        messages: '<',
        hasMore: '<',
        channel: '<',
        isWindowVisible: '<',
        user: '<',
        inboxKey: '<',
        presences: '<',
        readReceipts: '<',
        typingUsers: '<',
        unreadCount: '<',
        onChannelLeave: '&',
    },
});