class CommunicationUploaderController {
  private flowOptions;

  constructor(
    private $scope,
    private $element,
    private UserService,
    private CommunicationAPI,
    private CommunicationService,
    private API_END_POINT,
  ) {}

  $onInit() {
    this.flowOptions = {
      target: this.API_END_POINT + '/api/communication/upload',
      simultaneousUploads: 1,
      allowDuplicateUploads: true,
      chunkSize: 10 * 1024 * 1024,
      headers: {
        Authorization: 'Bearer ' + this.UserService.getToken(),
      },
    };
  }

  $postLink() {
    const flowDiv = this.$element.find('> div');

    const unsubscribe = this.$scope.$watch(
      () => flowDiv.scope(),
      (newVal) => {
        if (newVal && newVal.$flow) {
          this.CommunicationService.registerUploader(newVal.$flow);
          unsubscribe();
        }
      },
    );
  }

  onFileAdded($file) {
    const {currentChannel, currentInbox} = this.CommunicationService.state;
    const attachmentMessage = this.CommunicationAPI.initAttachment(
      currentChannel,
      currentInbox,
      $file,
    );
    $file.$$channelId = currentChannel.id;
    $file.$$inboxKey = currentInbox;
    $file.$$message = attachmentMessage;
  }

  onFileRemoved($file) {
    const {$$channelId, $$inboxKey, $$message} = $file;
    this.CommunicationAPI.cancelAttachmentMessage($$channelId, $$inboxKey, $$message);
  }

  onFileSuccess($file, $message) {
    const attachmentMessage = $file.$$message;

    const {file, thumb, width, height} = JSON.parse($message);

    attachmentMessage.attachments[0].url = file;

    if (thumb) {
      attachmentMessage.attachments[0].thumb_url = thumb;
    }

    if (width && height) {
      attachmentMessage.attachments[0].width = width;
      attachmentMessage.attachments[0].height = height;
    }

    this.CommunicationAPI.sendAttachmentMessage(
      $file.$$channelId,
      $file.$$inboxKey,
      attachmentMessage,
    );
  }
}

angular.module('app.communication').component('communicationUploader', {
  templateUrl: 'communication/components/communication-uploader.html',
  controller: CommunicationUploaderController,
});
