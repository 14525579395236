const SPORTCONTRACT_TAGGING_ID = '641036e1516f1df26a770f68';

class RecipientsAutocomplete {
  readonly initialySelectedUsers?: any[];
  readonly selections;
  readonly excludeIds;
  readonly restrictionEnabled;
  private onSelectionChange;

  private searchPromise;
  private staffSuggestions;
  private playerSuggestions;
  private organizationSuggestions;

  constructor(
    private $q,
    private CommunicationAPI,
    private _,
  ) {
    this.searchPromise = $q((resolve) => {});
    this.staffSuggestions = [];
    this.playerSuggestions = [];
    this.organizationSuggestions = [];
  }

  $onInit() {
    if (this.initialySelectedUsers) {
      const selections = (this.selections || []).concat(
        this.initialySelectedUsers.map((item) => {
          switch (item.type) {
            case 'organization':
            case 'agency':
              return this._getOrganizationDisplay(item);

            case 'team':
              return this._getTeamDisplay(item);

            default:
              return this._getUserDisplay(item);
          }
        }),
      );

      this.onSelectionChange({$selections: selections});
    }
  }

  select($selection) {
    if (!this.isSelected($selection)) {
      const selections = (this.selections || []).concat([$selection]);
      this.onSelectionChange({$selections: selections});
    }
  }

  unselect($selection, $index) {
    const selections = (this.selections || []).filter((item, index) => index !== $index);
    this.onSelectionChange({$selections: selections});
  }

  isSelected(item) {
    return (this.selections || []).some((selection) => selection.id === item.id);
  }

  searchForSuggestions(text) {
    if (!text) {
      return;
    }

    const excludeIds = this.excludeIds || [];
    this.searchPromise = this.CommunicationAPI.searchContacts(text).then(
      ({users, teams, organizations}) => {
        const userRows = (users || [])
          .map((result) => result._source)
          .filter((user) => excludeIds.indexOf(user._id) === -1)
          .map((user) => this._getUserDisplay(user))
          .filter((user) => !!user.roles.length);

        const teamRows = (teams || [])
          .map((result) => result._source)
          .filter((team) => excludeIds.indexOf(team._id) === -1)
          .slice(0, 10)
          .map((team) => this._getTeamDisplay(team));

        const orgRows = (organizations || [])
          .map((result) => result._source)
          .filter((org) => excludeIds.indexOf(org._id) === -1)
          .slice(0, 10)
          .map((org) => this._getOrganizationDisplay(org));

        this.playerSuggestions = userRows.filter((user) => {
          const organizationRoles = user.roles.filter((role) => /-organization/.test(role.context));

          return (
            organizationRoles &&
            organizationRoles.length &&
            organizationRoles.every((role) => role.roles.every((role) => role.name === 'player'))
          );
        });

        this.staffSuggestions = _.xorBy(userRows, this.playerSuggestions, 'id');
        this.organizationSuggestions = orgRows.concat(teamRows);
      },
    );

    return this.searchPromise;
  }

  _getTeamDisplay(team) {
    const teamName = this._.get(team, 'name', '');
    const teamLeague = this._.get(team, 'latestTeamStats.league.name', '');
    const teamCountry = this._.get(team, 'country.name', '');

    return {
      id: this._.get(team, '_id', ''),
      type: 'team',
      display: this._.joinIfPresent(' - ', teamName, teamLeague, teamCountry),
      restricted: this.restrictionEnabled && team.restricted,
    };
  }

  _getOrganizationDisplay(org) {
    const orgName = this._.get(org, 'name', '');
    const orgCountry = this._.get(org, 'country.name', '');

    return {
      id: this._.get(org, '_id', ''),
      type: 'organization',
      display: this._.joinIfPresent(' - ', orgName, orgCountry),
      restricted: this.restrictionEnabled && org.restricted,
    };
  }

  _getUserDisplay(user) {
    const firstName = this._.get(user, 'profile.firstName', '');
    const lastName = this._.get(user, 'profile.lastName', '');
    const fullName = this._.joinIfPresent(' ', firstName, lastName);

    return {
      id: this._.get(user, '_id', ''),
      type: 'user',
      restricted: this.restrictionEnabled && user.restricted,
      organization_id: this._.get(user, 'organization._id', ''),
      user_organization_role: '',
      roles: (user.roles || [])
        .filter((role) => !!role.resourceName)
        .filter((role) => role.id && role.id !== SPORTCONTRACT_TAGGING_ID)
        .map((role) => ({
          ...role,
          displayText:
            role.functions && role.functions.length
              ? role.functions.join(', ')
              : role.roles.map((r) => r.display).join(', '),
        })),
      display: fullName,
      licenses: user.profile.licenses,
    };
  }
}

angular.module('app.communication').component('recipientsAutocomplete', {
  templateUrl: 'communication/components/recipients-autocomplete.html',
  controller: RecipientsAutocomplete,
  bindings: {
    selections: '<',
    excludeIds: '<',
    restrictionEnabled: '<',
    placeholder: '@',
    onSelectionChange: '&?',
    initialySelectedUsers: '<?',
  },
});
