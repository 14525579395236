'use strict';

class ChannelLeaveConfirmModalController {
  private modalInstance;

  constructor($element, $timeout) {}

  confirm() {
    this.modalInstance.close('ok');
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
angular.module('app.communication').component('channelLeaveConfirmModal', {
  templateUrl: 'communication/components/modals/channel-leave-confirm.html',
  controller: ChannelLeaveConfirmModalController,
  bindings: {
    modalInstance: '<',
  },
});
