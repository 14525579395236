'use strict';

class AttachmentViewerController {
  private attachment;
  private showLoading;

  constructor(private $q) {
    this.showLoading = true;
  }

  $onInit() {}

  onImageLoaded() {
    this.showLoading = false;
  }
}

angular.module('app.communication').component('attachmentViewer', {
  templateUrl: 'communication/components/attachment-viewer.html',
  controller: AttachmentViewerController,
  bindings: {
    attachment: '<',
    onClose: '&',
  },
});
