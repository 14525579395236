'use strict';

class ChannelListController {
  private user;
  private inboxKey;
  private unreadCount;

  private unreadByChannel;

  $onChanges(changes) {
    if (
      changes.unreadCount &&
      changes.unreadCount.currentValue &&
      changes.unreadCount.currentValue.get(this.inboxKey)
    ) {
      this.unreadByChannel = changes.unreadCount.currentValue.get(this.inboxKey).toJS();
    }
  }
}

angular.module('app.communication').component('channelList', {
  templateUrl: 'communication/components/channel-list.html',
  bindings: {
    user: '<',
    inboxKey: '<',
    channels: '<',
    unreadCount: '<',
  },
  controller: ChannelListController,
});
