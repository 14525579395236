'use strict';

class MessageTextController {
  private text;
  private displayText;
  private regex;

  constructor(
    private $q,
    private CommunicationMemberCache,
  ) {
    this.regex = /(user|org)::([^\s,]*)/g;
  }

  $postLink() {
    const matches = this.text.match(this.regex);
    if (matches && matches.length) {
      const promises = matches.map((match) => {
        const [type, id] = match.split('::');
        return this.CommunicationMemberCache.getDisplay(type, id).then((displayText) => [
          match,
          displayText,
        ]);
      });

      this.$q.all(promises).then((pairs) => {
        let text = this.text;
        pairs.forEach((pair) => {
          const [match, displayText] = pair;
          text = text.replace(match, displayText);
        });
        this.displayText = text;
      });

      let text = this.text;
      matches.forEach((match) => (text = text.replace(match, '...')));
      this.displayText = text;
    } else {
      this.displayText = this.text;
    }
  }
}

angular.module('app.communication').component('messageText', {
  template: '{{$ctrl.displayText}}',
  controller: MessageTextController,
  bindings: {
    text: '<',
  },
});
