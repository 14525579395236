'use strict';

class ChannelListItemController {
  private channel;
  private user;
  private unreadCount;
  private inboxKey;

  private inboxType;
  private inboxId;
  private participants;

  private isChatWithOrganization;
  private isGroupChat;
  private isPrivateChat;
  private isEmpty;
  private otherUserIds;
  private otherOrganizationIds;
  private otherParticipants;

  $onInit() {
    const [inboxType, inboxId] = this.inboxKey.split(':');
    this.inboxType = inboxType;
    this.inboxId = inboxId;

    const organizations = this.channel.organizations || [];
    const users = this.channel.users || [];

    this.otherUserIds =
      this.inboxType === 'user'
        ? users.filter((user) => user.id !== this.inboxId).map((user) => user.id)
        : users.map((user) => user.id);

    this.otherOrganizationIds =
      this.inboxType === 'org'
        ? organizations.filter((org) => org.id !== this.inboxId).map((org) => org.id)
        : organizations.map((org) => org.id);

    const otherUsers = this.otherUserIds.map((id) => ({id, type: 'user'}));
    const otherOrganizations = this.otherOrganizationIds.map((id) => ({id, type: 'org'}));
    this.otherParticipants = otherUsers.concat(otherOrganizations);

    this.isChatWithOrganization =
      !this.otherUserIds.length && this.otherOrganizationIds.length === 1;
    this.isPrivateChat = !this.otherOrganizationIds.length && this.otherUserIds.length === 1;
    this.isGroupChat = this.otherParticipants.length >= 2;
    this.isEmpty = this.otherParticipants.length == 0;
  }
}

angular.module('app.communication').component('channelListItem', {
  templateUrl: 'communication/components/channel-list-item.html',
  controller: ChannelListItemController,
  bindings: {
    user: '<',
    channel: '<',
    unreadCount: '<',
    inboxKey: '<',
  },
});
