'use strict';
var MessageSenderController = (function () {
    function MessageSenderController($q, CommunicationMemberCache, _) {
        this.$q = $q;
        this.CommunicationMemberCache = CommunicationMemberCache;
        this._ = _;
    }
    MessageSenderController.prototype.$onInit = function () {
        var _this = this;
        this.$promise = this.$q
            .all({
            organization: this.organizationId
                ? this.CommunicationMemberCache.getMember('org', this.organizationId)
                : this.$q.resolve(),
            user: this.CommunicationMemberCache.getMember('user', this.userId),
        })
            .then(function (_a) {
            var user = _a.user, organization = _a.organization;
            _this.user = user;
            _this.organization = organization;
        });
    };
    Object.defineProperty(MessageSenderController.prototype, "displayTextOrg", {
        get: function () {
            if (this.organization && this.user) {
                var organizationId = this.organization.id;
                var userDisplayName = this._.joinIfPresent(' ', this._.get(this.user, 'first_name'), this._.get(this.user, 'last_name'));
                var userOrganizationRole = this._.find(this.user.roles, { id: organizationId });
                if (!userOrganizationRole) {
                    return this.organization.name || 'Unknown Organization';
                }
                var roleDisplay = userOrganizationRole.roles.map(function (role) { return role.display; }).join(', ');
                return "".concat(this.organization.name, " - ").concat(userDisplayName, " (").concat(roleDisplay, ")");
            }
            if (this.organization) {
                return this.organization.name;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MessageSenderController.prototype, "displayTextUser", {
        get: function () {
            if (this.user) {
                var userDisplayName = this._.joinIfPresent(' ', this._.get(this.user, 'first_name'), this.short ? '' : this._.get(this.user, 'last_name'));
                return userDisplayName || 'Unknown User';
            }
        },
        enumerable: false,
        configurable: true
    });
    return MessageSenderController;
}());
angular.module('app.communication').component('messageSender', {
    templateUrl: 'communication/components/message-sender.html',
    controller: MessageSenderController,
    bindings: {
        short: '<',
        userId: '<',
        organizationId: '<',
    },
});