'use strict';

class AddParticipantsModalController {
  private modalInstance;
  private canSeeChannelHistory;
  private channel;
  private excludeIds;
  private selections;

  constructor() {
    this.selections = [];
  }

  $onInit() {
    const excludeUsers = this.channel.users;
    const excludeOrgs = this.channel.organizations;
    this.excludeIds = excludeUsers.concat(excludeOrgs).map((item) => item.id);
  }

  confirm() {
    this.modalInstance.close({
      selections: this.selections,
      canSeeChannelHistory: this.canSeeChannelHistory === 'yes',
    });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
angular.module('app.communication').component('addParticipantsModal', {
  templateUrl: 'communication/components/modals/add-participants.html',
  controller: AddParticipantsModalController,
  bindings: {
    modalInstance: '<',
    channel: '<',
  },
});
