'use strict';
var NewChatButtonController = (function () {
    function NewChatButtonController($state, $uibModal, $timeout, $filter) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$filter = $filter;
    }
    Object.defineProperty(NewChatButtonController.prototype, "isDisabled", {
        get: function () {
            if (this.disabled) {
                return this.disabled;
            }
            if (this.initialySelectedUsers && this.initialySelectedUsers.length === 1) {
                var targetUser = this.initialySelectedUsers[0];
                return (!targetUser ||
                    !targetUser._id ||
                    (typeof targetUser.restriction !== 'undefined' &&
                        targetUser.restriction !== null &&
                        targetUser.restriction !== 0));
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewChatButtonController.prototype, "tooltipText", {
        get: function () {
            if (this.type === 'contact-sc-mini') {
                return this.translate('communication.Report_wrong_data');
            }
            if (!this.isDisabled) {
                return this.translate('general.general.Contact');
            }
            if (this.initialySelectedUsers && this.initialySelectedUsers.length === 1) {
                var targetUser = this.initialySelectedUsers[0];
                if (!targetUser || !targetUser._id) {
                    return this.translate('general.general.User_not_exists');
                }
                if (targetUser.restriction === 5) {
                    return this.translate('general.upgrade_plan_send_message');
                }
                else if (targetUser.restriction === 4) {
                    return this.translate('general.general.Player_outside_agency');
                }
                else {
                    return this.translate('general.general.Contact_not_allow');
                }
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    NewChatButtonController.prototype.translate = function (text) {
        return this.$filter('translate')(text);
    };
    NewChatButtonController.prototype.startNewChat = function () {
        var _this = this;
        if (this.isDisabled) {
            return;
        }
        var initialySelectedUsers = this.initialySelectedUsers;
        var type = this.type;
        var template = '<new-chat modal-instance="$ctrl.modalInstance" initialy-selected-users="$ctrl.initialySelectedUsers" />';
        if (this.type === 'contact-sc-mini' ||
            this.type === 'contact-sc-advanced-analytics' ||
            this.type === 'contact-sc-report-problem') {
            template = '<new-bug-report modal-instance="$ctrl.modalInstance"></new-bug-report>';
        }
        var modalInstance = this.$uibModal.open({
            size: 'lg',
            template: template,
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.initialySelectedUsers = initialySelectedUsers;
                },
            ],
            controllerAs: '$ctrl',
        });
        modalInstance.result.then(function (_a) {
            var inboxKey = _a.inboxKey, channel = _a.channel;
            var _b = inboxKey.split(':'), inboxType = _b[0], inboxId = _b[1];
            if (inboxType === 'user') {
                inboxId = 'private';
            }
            if (typeof _this.onChannelCreated === 'function') {
                _this.onChannelCreated({
                    $inboxId: inboxId,
                    $channelId: channel.id,
                });
            }
            else {
                _this._goToChannel(inboxId, channel.id);
            }
        }, angular.noop);
    };
    NewChatButtonController.prototype._goToChannel = function (inboxId, channelId) {
        var _this = this;
        this.$timeout(function () {
            if (channelId) {
                _this.$state.go('root.communication.inbox.channel', {
                    id: inboxId,
                    channelId: channelId,
                });
            }
            else {
                _this.$state.go('root.communication.inbox', { id: inboxId });
            }
        }, 200);
    };
    return NewChatButtonController;
}());
angular.module('app.communication').component('newChatButton', {
    templateUrl: 'communication/components/new-chat-button.html',
    controller: NewChatButtonController,
    bindings: {
        onChannelCreated: '&?',
        initialySelectedUsers: '<?',
        type: '@?',
        disabled: '<?',
    },
});