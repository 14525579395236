'use strict';

function ChannelDisplayFilter(_) {
  return (channel, currentUserId, inboxKey) => {
    const organizationId = inboxKey.split('org:')[1];
    const otherUsers = organizationId
      ? channel.users
      : channel.users.filter((user) => user.id !== currentUserId);
    const otherOrgs = organizationId
      ? channel.organizations.filter((org) => org.id !== organizationId)
      : channel.organizations;

    if (otherUsers.length === 1 && !otherOrgs.length) {
      return getFullUserTitle(otherUsers[0]);
    } else {
      return otherUsers
        .map((user) => getShortUserTitle(user))
        .concat(otherOrgs.map((org) => org.name))
        .join(', ');
    }
  };

  function getFullUserTitle(user) {
    return _.joinIfPresent(' ', user.first_name, user.last_name);
  }

  function getShortUserTitle(user) {
    return user.first_name;
  }
}

angular.module('app.communication').filter('channelDisplay', ChannelDisplayFilter);
