'use strict';
var MessageSenderProfileCard = (function () {
    function MessageSenderProfileCard($q, CommunicationMemberCache, _) {
        this.$q = $q;
        this.CommunicationMemberCache = CommunicationMemberCache;
        this._ = _;
    }
    MessageSenderProfileCard.prototype.$onInit = function () {
        var _this = this;
        var deferred = this.$q.defer();
        this.$promise = deferred.promise;
        this.$q
            .all({
            organization: this.organizationId
                ? this.CommunicationMemberCache.getMember('org', this.organizationId)
                : this.$q.resolve(),
            user: this.CommunicationMemberCache.getMember('user', this.userId),
        })
            .then(function (_a) {
            var user = _a.user, organization = _a.organization;
            if (user) {
                user = angular.copy(user);
                user.roles = (user.roles || [])
                    .filter(function (role) { return role && !!role.id; })
                    .map(function (role) {
                    if (role.functions && role.functions.length) {
                        role.functionsDisplay = role.functions.join(', ');
                    }
                    else {
                        role.functionsDisplay = role.roles.map(function (role) { return role.display; }).join(', ');
                    }
                    return role;
                });
                _this.user = user;
            }
            _this.organization = organization;
        })
            .then(function () {
            if (_this.organization && _this.organization.organization_type === 'team') {
                return _this.CommunicationMemberCache.fetchTeamLeagues(_this.organization.id).then(function (leagues) { return (_this.leagues = leagues); });
            }
        })
            .then(function () { return deferred.resolve(); });
    };
    return MessageSenderProfileCard;
}());
angular.module('app.communication').component('messageSenderProfileCard', {
    templateUrl: 'communication/components/message-sender-profile-card.html',
    controller: MessageSenderProfileCard,
    bindings: {
        userId: '<',
        organizationId: '<',
    },
});