'use strict';

class InboxNameController {
  private user;
  private inboxKey;
  private unreadCount;

  private name;

  constructor() {}

  $onChanges(changes) {
    if (changes.inboxKey && changes.inboxKey.currentValue) {
      const [type, id] = changes.inboxKey.currentValue.split(':');

      this.name =
        type === 'user'
          ? `${this.user.profile.firstName} ${this.user.profile.lastName}`
          : _.get(_.find(this.user.accounts, {_id: id}), 'display');
    }
  }
}

angular.module('app.communication').component('inboxName', {
  templateUrl: 'communication/components/inbox-name.html',
  controller: InboxNameController,
  bindings: {
    user: '<',
    inboxKey: '<',
    unreadCount: '<',
  },
});
