'use strict';

class MessageSenderAvatarController {
  private organizationId;
  private userId;

  private user;
  private organization;
  private $promise;

  constructor(
    private $q,
    private CommunicationMemberCache,
    private _,
  ) {}

  $onInit() {
    this.$promise = this.$q
      .all({
        organization: this.organizationId
          ? this.CommunicationMemberCache.getMember('org', this.organizationId)
          : this.$q.resolve(),
        user: this.CommunicationMemberCache.getMember('user', this.userId),
      })
      .then(({user, organization}) => {
        this.user = user;
        this.organization = organization;
      });
  }
}

angular.module('app.communication').component('messageSenderAvatar', {
  templateUrl: 'communication/components/message-sender-avatar.html',
  controller: MessageSenderAvatarController,
  bindings: {
    organizationId: '<',
    userId: '<',
  },
});
