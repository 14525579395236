'use strict';
var NotificationsHintController = (function () {
    function NotificationsHintController(NotificationCenter) {
        this.$notificationCenter = NotificationCenter;
    }
    Object.defineProperty(NotificationsHintController.prototype, "isSupported", {
        get: function () {
            return this.$notificationCenter.native.isSupported;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationsHintController.prototype, "isEnabled", {
        get: function () {
            return this.$notificationCenter.native.isEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationsHintController.prototype, "isDenied", {
        get: function () {
            return this.$notificationCenter.native.isDenied;
        },
        enumerable: false,
        configurable: true
    });
    NotificationsHintController.prototype.requestNotificationPermissions = function () {
        this.$notificationCenter.native.requestPermissions();
    };
    return NotificationsHintController;
}());
angular.module('app.communication').component('notificationsHint', {
    templateUrl: 'communication/components/notifications-hint.html',
    controller: NotificationsHintController,
});