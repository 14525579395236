'use strict';

function UserDisplayFilter(_) {
  return (user) => {
    const fullName = _.joinIfPresent(' ', user.first_name, user.last_name);
    const organizationName = _.get(user, 'organization_name', '');
    const organizationRole = _.get(user, 'user_organization_role', '');

    return _.joinIfPresent(' - ', fullName, organizationName, organizationRole);
  };
}

function UserDisplayShortFilter() {
  return (user) => {
    return user.first_name || user.last_name;
  };
}

angular
  .module('app.communication')
  .filter('userDisplay', UserDisplayFilter)
  .filter('userDisplayShort', UserDisplayShortFilter);
