'use strict';

class MessageListController {
  private messages;
  private hasMore;
  private channel;
  private isWindowVisible;
  private unreadCount;
  private user;
  private inboxKey;
  private presences;
  private typingUsers;
  private readReceipts;
  private byId;
  private otherParticipants;
  private onChannelLeave;
  private scheduleTypingStop;

  private $messagesContainer;

  private olderMessagesPromise;
  private readReceiptsByMessage;

  private attachmentViewerVisible: boolean;
  private activeAttachment;

  private _markReadDebounced;
  private _scrollToLastMessageDebounced;

  constructor(
    private $element,
    private $scope,
    private $timeout,
    private $filter,
    private $uibModal,
    private CommunicationAPI,
    private CommunicationService,
  ) {
    this.byId = {};
    this._markReadDebounced = _.debounce(this._markRead, 1000);
    this._scrollToLastMessageDebounced = _.debounce(this._scrollToLastMessage, 100);
  }

  $onChanges(changesObj) {
    if (changesObj.channel && changesObj.channel.currentValue) {
      const channel = changesObj.channel.currentValue;
      channel.users.forEach((user) => (this.byId[user.id] = user));
      channel.organizations.forEach((org) => (this.byId[org.id] = org));

      const [inboxType, inboxId] = this.inboxKey.split(':');
      const organizations = this.channel.organizations || [];
      const users = this.channel.users || [];

      const otherUserIds =
        inboxType === 'user'
          ? users.filter((user) => user.id !== inboxId).map((user) => user.id)
          : users.map((user) => user.id);

      const otherOrganizationIds =
        inboxType === 'org'
          ? organizations.filter((org) => org.id !== inboxId).map((org) => org.id)
          : organizations.map((org) => org.id);

      const otherUsers = otherUserIds.map((id) => ({id, type: 'user'}));
      const otherOrganizations = otherOrganizationIds.map((id) => ({id, type: 'org'}));

      this.otherParticipants = otherUsers.concat(otherOrganizations);
    }

    if (changesObj.messages && changesObj.messages.currentValue) {
      const previousMessages = changesObj.messages.previousValue;
      const currentMessages = changesObj.messages.currentValue;

      let anchorMessage;
      if (
        previousMessages &&
        currentMessages &&
        previousMessages.size &&
        currentMessages.size &&
        previousMessages.first() !== currentMessages.first()
      ) {
        anchorMessage = previousMessages.first();
      }

      if (anchorMessage) {
        this._scrollToMessage(anchorMessage.id, 100);
      } else {
        this._scrollToLastMessageDebounced();
      }

      const lastMessage = currentMessages.last();
      if (!this.isOwnMessage(lastMessage) && this.unreadCount && this.isWindowVisible) {
        this._markReadDebounced(lastMessage);
      }
    }

    if (
      changesObj.readReceipts &&
      changesObj.readReceipts.currentValue &&
      changesObj.readReceipts.currentValue.size
    ) {
      this.readReceiptsByMessage = _.groupBy(
        changesObj.readReceipts.currentValue.toJS(),
        'message_id',
      );
    }

    if (changesObj.unreadCount && changesObj.unreadCount.currentValue && this.messages) {
      const lastMessage = this.messages.last();
      if (lastMessage && !this.isOwnMessage(lastMessage) && this.isWindowVisible) {
        this._markReadDebounced(lastMessage);
      }
    }

    if (
      changesObj.isWindowVisible &&
      changesObj.isWindowVisible.currentValue &&
      !changesObj.isWindowVisible.previoousValue
    ) {
      const lastMessage = this.messages && this.messages.last();
      if (lastMessage && !this.isOwnMessage(lastMessage) && this.unreadCount) {
        this._markReadDebounced(lastMessage);
      }
    }
  }

  $postLink() {
    this.$timeout(() => {
      this.CommunicationService.$flowUploaderInstance.assignDrop(this.$element);
    }, 200);
  }

  isOwnMessage(message) {
    const organizationId = this.inboxKey.split('org:')[1];
    if (organizationId) {
      return message.organization_id === organizationId;
    } else {
      return message.user_id === this.user._id && !message.organization_id;
    }
  }

  isSameSenderWithPreviousMessage(message, previousMessage) {
    return previousMessage && previousMessage.user_id === message.user_id;
  }

  getOlderMessages() {
    if (this.messages && this.messages.size && this.hasMore) {
      this.olderMessagesPromise = this.CommunicationAPI.getOlderMessages(this.channel.id);
    }
  }

  leaveChannel() {
    const modalInstance = this.$uibModal.open({
      size: 'md',
      template: '<channel-leave-confirm-modal modal-instance="$ctrl.modalInstance" />',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
      controllerAs: '$ctrl',
    });
    modalInstance.result
      .then(() => {
        this.onChannelLeave();
      })
      .catch(() => {});
  }

  addMembers() {
    const channel = this.channel;
    const modalInstance = this.$uibModal.open({
      size: 'md',
      template:
        '<add-participants-modal channel="$ctrl.channel" modal-instance="$ctrl.modalInstance" />',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.channel = channel;
        },
      ],
      controllerAs: '$ctrl',
    });

    modalInstance.result
      .then(({selections, canSeeChannelHistory}) => {
        this.CommunicationAPI.addMembers(channel, selections, canSeeChannelHistory);
      })
      .catch(() => {});
  }

  viewAttachment(message) {
    this.attachmentViewerVisible = true;
    this.activeAttachment = message.attachments[0];
  }

  _scrollToMessage(id, offset = 0) {
    this.$timeout(() => {
      this.$messagesContainer = this.$messagesContainer || this.$element.find('.chat-messages');
      const $msg = this.$element.find(`#msg-${id}`);
      if ($msg.length) {
        this.$messagesContainer.scrollTo($msg, offset, 200);
      }
    }, 0);
  }

  _scrollToLastMessage() {
    this.$messagesContainer = this.$messagesContainer || this.$element.find('.chat-messages');
    const $lastItem = this.$messagesContainer.find(`li.item:last`);
    if ($lastItem.length) {
      this.$messagesContainer.scrollTo($lastItem, 0, 0);
    }
  }

  _markRead(message) {
    this.CommunicationAPI.markRead(this.channel.id, this.inboxKey, message);
    this.$scope.$apply();
  }

  isUserOnline(userId) {
    return !!this.presences[userId];
  }
}

angular.module('app.communication').component('messageList', {
  templateUrl: 'communication/components/message-list.html',
  controller: MessageListController,
  bindings: {
    messages: '<',
    hasMore: '<',
    channel: '<',
    isWindowVisible: '<',
    user: '<',
    inboxKey: '<',
    presences: '<',
    readReceipts: '<',
    typingUsers: '<',
    unreadCount: '<',
    onChannelLeave: '&',
  },
});
