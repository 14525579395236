'use strict';

class NewChatButtonController {
  private onChannelCreated;
  private initialySelectedUsers;
  private type: string;
  private disabled?: boolean;

  constructor(
    private $state,
    private $uibModal,
    private $timeout,
    private $filter,
  ) {}

  get isDisabled() {
    if (this.disabled) {
      return this.disabled;
    }

    if (this.initialySelectedUsers && this.initialySelectedUsers.length === 1) {
      const targetUser = this.initialySelectedUsers[0];

      return (
        !targetUser ||
        !targetUser._id ||
        (typeof targetUser.restriction !== 'undefined' &&
          targetUser.restriction !== null &&
          targetUser.restriction !== 0)
      );
    }
  }

  get tooltipText() {
    if (this.type === 'contact-sc-mini') {
      return this.translate('communication.Report_wrong_data');
    }

    if (!this.isDisabled) {
      return this.translate('general.general.Contact');
    }

    if (this.initialySelectedUsers && this.initialySelectedUsers.length === 1) {
      const targetUser = this.initialySelectedUsers[0];

      if (!targetUser || !targetUser._id) {
        return this.translate('general.general.User_not_exists');
      }

      if (targetUser.restriction === 5) {
        return this.translate('general.upgrade_plan_send_message');
      } else if (targetUser.restriction === 4) {
        return this.translate('general.general.Player_outside_agency');
      } else {
        return this.translate('general.general.Contact_not_allow');
      }
    }

    return '';
  }

  translate(text) {
    return this.$filter('translate')(text);
  }

  startNewChat() {
    if (this.isDisabled) {
      return;
    }

    const initialySelectedUsers = this.initialySelectedUsers;
    const type = this.type;

    let template =
      '<new-chat modal-instance="$ctrl.modalInstance" initialy-selected-users="$ctrl.initialySelectedUsers" />';

    if (
      this.type === 'contact-sc-mini' ||
      this.type === 'contact-sc-advanced-analytics' ||
      this.type === 'contact-sc-report-problem'
    ) {
      template = '<new-bug-report modal-instance="$ctrl.modalInstance"></new-bug-report>';
    }

    const modalInstance = this.$uibModal.open({
      size: 'lg',
      template,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.initialySelectedUsers = initialySelectedUsers;
        },
      ],
      controllerAs: '$ctrl',
    });

    modalInstance.result.then(({inboxKey, channel}) => {
      // eslint-disable-next-line prefer-const
      let [inboxType, inboxId] = inboxKey.split(':');
      if (inboxType === 'user') {
        inboxId = 'private';
      }
      if (typeof this.onChannelCreated === 'function') {
        this.onChannelCreated({
          $inboxId: inboxId,
          $channelId: channel.id,
        });
      } else {
        this._goToChannel(inboxId, channel.id);
      }
    }, angular.noop);
  }

  _goToChannel(inboxId, channelId) {
    this.$timeout(() => {
      if (channelId) {
        this.$state.go('root.communication.inbox.channel', {
          id: inboxId,
          channelId,
        });
      } else {
        this.$state.go('root.communication.inbox', {id: inboxId});
      }
    }, 200);
  }
}

angular.module('app.communication').component('newChatButton', {
  templateUrl: 'communication/components/new-chat-button.html',
  controller: NewChatButtonController,
  bindings: {
    onChannelCreated: '&?',
    initialySelectedUsers: '<?',
    type: '@?',
    disabled: '<?',
  },
});
