'use strict';

class MessageSenderProfileCard {
  private userId;
  private organizationId;
  private displayText;

  private user;
  private organization;
  private leagues;
  private $promise;

  constructor(
    private $q,
    private CommunicationMemberCache,
    private _,
  ) {}

  $onInit() {
    const deferred = this.$q.defer();
    this.$promise = deferred.promise;

    this.$q
      .all({
        organization: this.organizationId
          ? this.CommunicationMemberCache.getMember('org', this.organizationId)
          : this.$q.resolve(),
        user: this.CommunicationMemberCache.getMember('user', this.userId),
      })
      .then(({user, organization}) => {
        if (user) {
          user = angular.copy(user);
          user.roles = (user.roles || [])
            .filter((role) => role && !!role.id)
            .map((role) => {
              if (role.functions && role.functions.length) {
                role.functionsDisplay = role.functions.join(', ');
              } else {
                role.functionsDisplay = role.roles.map((role) => role.display).join(', ');
              }
              return role;
            });
          this.user = user;
        }
        this.organization = organization;
      })
      .then(() => {
        if (this.organization && this.organization.organization_type === 'team') {
          return this.CommunicationMemberCache.fetchTeamLeagues(this.organization.id).then(
            (leagues) => (this.leagues = leagues),
          );
        }
      })
      .then(() => deferred.resolve());
  }
}

angular.module('app.communication').component('messageSenderProfileCard', {
  templateUrl: 'communication/components/message-sender-profile-card.html',
  controller: MessageSenderProfileCard,
  bindings: {
    userId: '<',
    organizationId: '<',
  },
});
